"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "modalContainer" };
var _hoisted_2 = ["onClick"];
var _hoisted_3 = ["innerHTML"];
var toastr_1 = require("../ts/toastr");
exports.default = (0, vue_1.defineComponent)({
    __name: 'Toastr',
    setup: function (__props) {
        var toastr = (0, toastr_1.useToastr)();
        function getStyle(type) {
            switch (type.toLowerCase()) {
                case "error":
                    return "error";
                case "warning":
                    return "warning";
                case "information":
                    return "information";
                case "success":
                    return "success";
            }
        }
        function close(id) {
            toastr.close(id);
        }
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)((0, vue_2.unref)(toastr).allToastrs.value, function (item) {
                    return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", (0, vue_2.mergeProps)({
                        key: item.id,
                        ref_for: true
                    }, { 'data-id': item.id }, {
                        onClick: function ($event) { return (close(item.id)); }
                    }), [
                        (0, vue_2.createElementVNode)("div", {
                            class: (0, vue_2.normalizeClass)(["modal", getStyle(item.type)]),
                            innerHTML: item.message
                        }, null, 10, _hoisted_3)
                    ], 16, _hoisted_2));
                }), 128))
            ]));
        };
    }
});
