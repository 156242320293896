<template>
    <Bubble>
        <div v-if="successfulReset" class="resetPasswordValidate errorMessage">
            {{ t('success') }}

            <router-link :to="{ name: 'login' }" class="continue">{{ t('loginLink') }}</router-link>
        </div>

        <div v-else-if="tokenError" class="resetPasswordValidate errorMessage">
            {{ t('error.invalidToken') }}
            <router-link :to="{ name: 'resetpassword' }" class="continue">{{ t('error.resetPasswordLink') }}</router-link>
        </div>

        <div v-else-if="unknownError" class="resetPasswordValidate errorMessage">
            {{ t('error.unknown') }}
        </div>

        <div v-else-if="validating" class="resetPasswordValidate-loading">
            <Loader :style="'white'" />
        </div>

        <form v-else class="resetPasswordValidate" @submit.prevent="resetPassword">
            <!-- Hint voor password managers -->
            <input type="text" id="username" name="username" :value="username" autocomplete="username" style="display: none" />
            
            <div class="password-input">
                <input class="form-control" id="password" ref="passwordInput" :class="{ error: attemptedReset && !passwordValid }" :disabled="validating" :placeholder="t('placeholders.password')" type="password" v-model="password" autofocus autocomplete="new-password" @focus="passwordRequirementsVisible = true" @blur="passwordRequirementsVisible = false" />
                <Transition>
                    <PasswordRequirementsChecker 
                    v-if="passwordRequirementsVisible" 
                    ref="passwordChecker" 
                    :newPassword="password"
                    :newPasswordConfirm="passwordConfirm"
                    :wachtwoord-requirements="passwordRequirements" 
                    :checkNewPaswswordConfirmed="false"
                    class="passwordRequirements" 
                    :style="{ 'top': passwordRequirementsTopOffset }" />
                </Transition>
            </div>
            <input class="form-control" id="passwordConfirm" ref="passwordconfirmInput" :class="{ error: attemptedReset && !passwordConfirmValid }" :disabled="validating" :placeholder="t('placeholders.passwordConfirm')" type="password" v-model="passwordConfirm" autocomplete="new-password" />
            <input type="submit" id="reset" class="btn" :value="t('submit')" />

            <div class="status">
                <Loader v-if="validating && message === null" :style="'white'" />
                {{ message }}
            </div>
        </form>
    </Bubble>
</template>

<i18n lang="yaml" src="./locale/ResetPasswordValidate.yaml" />

<script lang="ts" setup>
import { ref, computed, onMounted, nextTick, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useStore } from '@/store';
import Loader from '@pf/vue3/Loader.vue';
import Bubble from '../components/Bubble.vue';
import axios, { AxiosResponse } from 'axios';
import PasswordRequirementsChecker, { WachtwoordRequirements } from "shared/vue/PasswordRequirementsChecker.vue";

const i18n = useI18n();
const { t } = i18n;
const route = useRoute();
const store = useStore();

const passwordInput = ref<HTMLInputElement | null>(null);
const passwordconfirmInput = ref<HTMLInputElement | null>(null);
const passwordChecker = ref<Element>();

const passwordRequirementsVisible = ref<boolean>(false);

const passwordRequirements = ref<WachtwoordRequirements>({
  forbiddenUserInfo: [],
  includeCapital: false,
  includeNumber: false,
  includeSymbol: false,
  minimumLength: 8
});

const passwordRequirementsTopOffset = computed(() => {
    if (!passwordRequirements.value)
        return "";

    let offset = -45; // Standaard offset + tekst "wachtwoord eisen.
    offset -= 20; // Lengte requirement offset.
    if (passwordRequirements.value.includeCapital)
        offset -= 20;

    if (passwordRequirements.value.includeNumber)
        offset -= 20;

    if (passwordRequirements.value.includeSymbol)
        offset -= 20;


    return offset + "px";
});

interface ValidateResetPasswordRequest
{
    Token: string;
}

interface ValidateResetPasswordTokenResponse
{
    Email: string,
    WachtwoordLengte: number,
    IsHoofdletterVerplicht: boolean,
    IsCijferVerplicht: boolean,
    IsSpeciaalTekenVerplicht: boolean,
}


const token = ref<string | null>(null);
const validating = ref(true);
const tokenError = ref(false);
const unknownError = ref(false);
const attemptedReset = ref(false);
const successfulReset = ref(false);
const password = ref('',);
const passwordConfirm = ref('',);
const message = ref<string | null>(null);


interface SavePasswordRequest
{
    Token: string;
    Password: string;
}


const username = computed<string>(() =>
{
    return store.username.value;
});


const passwordValid = computed<boolean>(() =>
{
    return !!password.value;
});

const passwordConfirmValid = computed<boolean>(() =>
{
    return passwordValid.value && passwordConfirm.value === password.value;
});
    
onMounted(() =>
{
    document.title = t('title');
    watch(i18n.locale, () => { document.title = t('title'); });

    if (!!route.query.token)
        token.value = route.query.token.toString();

    validateToken();
});


async function validateToken()
{
    const response = await axios.post<ValidateResetPasswordRequest, AxiosResponse<ValidateResetPasswordTokenResponse>>('/v2/resetpassword/validate', 
        {
            Token: token.value
        } as ValidateResetPasswordRequest);

    switch (response.status)
    {
        case 200:
            store.username.value = response.data.Email;

            passwordRequirements.value.includeCapital = response.data.IsHoofdletterVerplicht;
            passwordRequirements.value.includeNumber = response.data.IsCijferVerplicht;
            passwordRequirements.value.includeSymbol = response.data.IsSpeciaalTekenVerplicht;
            passwordRequirements.value.minimumLength = response.data.WachtwoordLengte;

            break;

        case 401:
            tokenError.value = true;
            break;

        default:
            unknownError.value = true;
            break;
    }

    validating.value = false;

    nextTick(() =>
    {
        passwordInput.value?.focus();
    });
}
        
        
async function resetPassword()
{
    attemptedReset.value = true;

    if (!passwordValid.value)
    {
        showMessage(t('error.nopassword'));
        nextTick(() =>
        {
            passwordInput.value?.focus();
        });

        return;
    }

    if (!passwordConfirmValid.value)
    {
        showMessage(t('error.confirmpassword'));
        nextTick(() =>
        {
            passwordconfirmInput.value?.focus();
        });

        return;
    }

    clearMessage();
    validating.value = true;
    try
    {
        const response = await axios.post('/v2/resetpassword/save', 
            {
                Token: token.value,
                Password: password.value
            } as SavePasswordRequest);

        switch (response.status)
        {
            case 200:
                // De huidige pagina switchen in plaats van weg navigeren, anders kun je te makkelijk terug                    
                successfulReset.value = true;
                break;

            case 406:
                showMessage(t('error.notstrongenough'));
                nextTick(() =>
                {
                    passwordInput.value?.focus();
                });

                break;

            default:
                showMessage(t('error.unknown'));
                break;
        }
    }
    finally
    {
        validating.value = false;
    }
}


function clearMessage()
{
    message.value = null;
}


function showMessage(value: string)
{
    message.value = value;
}
</script>

<style lang="scss">
@import "../variables.scss";


.resetPasswordValidate-loading
{
    margin-top: 60px;
    text-align: center;
}


.resetPasswordValidate
{
    padding-top: 42px;
    padding-left: 65px;
    padding-right: 65px;
    text-align: center;
    position: relative;

    .form-control
    {
        margin-bottom: 1rem;
        width: 100%;
    }

    .btn
    {
        margin-left: auto;
        margin-right: auto;
    }


    .status
    {
        color: white;
        margin-top: .5rem;
        margin-bottom: .5rem;
        height: 2em;
    }

    &.errorMessage
    {
        color: white;
    }


    .continue
    {
        display: block;
        margin-top: 2em;
        color: #379;
    }

    .password-input {
        position: relative;

        .passwordRequirements {
            color: #8a6d3b;
            background-color: #fcf8e3;
            border: solid 1px #faebcc;
            border-radius: 15px;
            position:absolute;
            padding: 5px;
            left: 2%;
            width: calc(95% - 10px);
            
            &:before {
                content: "";
                display: block;
                width: 0px;
                height: 0px;
                position: absolute;
                left: calc(50% - 10px);
                bottom: -10px;
                border-right: 10px solid transparent;
                border-left: 10px solid transparent;
                border-top: 10px solid #fcf8e3;
        
            }
    
            ul {
                list-style-type:none;
                margin: 0;
                padding: 5px 0px 0px 10px;
                
                li {
                    text-align: left;
                    margin-left: 20px;
                }
                
                li:before {
                    content: "-";
                    padding-right: 5px;
                }
            }
        }
    }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
