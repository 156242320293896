<template>
    <Bubble>
        <div v-if="validatingExisting" class="login-loading">
            <Loader :style="'white'" />
        </div>

        <div v-else-if="passwordExpired" class="password-expired">
            <p>{{ t('error.passwordExpiredExplaination') }}</p>
            <p>{{ t('error.passwordEmailSent') }}</p>
            <button @click="passwordExpired = false">Terug naar inloggen</button>
        </div>

        <div v-else-if="passwordInvalidated" class="password-invalidated">
            <p>{{ t('error.passwordInvalidatedExplaination') }}</p>
            <p>{{ t('error.passwordEmailSent') }}</p>
            <button @click="passwordInvalidated = false">Terug naar inloggen</button>
        </div>

        <form v-else class="login" @submit.prevent="validateLogin">
            <input class="form-control" id="username" :class="{ error: attemptedLogin && !usernameValid }" :disabled="validating" :placeholder="t('placeholders.username')" type="text" ref="usernameInput" :value="username" @input="setUsername" autofocus autocomplete="username" />
            <input class="form-control" id="password" :class="{ error: attemptedLogin && !passwordValid }" :disabled="validating" :placeholder="t('placeholders.password')" type="password" ref="passwordInput" v-model="password" autocomplete="current-password" />

            <input type="submit" id="login" class="btn" :value="t('submit')" :disabled="validating" />

            <div class="status">
                <Loader v-if="validating && errorMessage === null" :style="'white'" />
                {{ translatedMessage?.value }}
            </div>

            <router-link :to="{ name: 'resetpassword' }" class="resetpassword">{{ t('forgotpassword') }}</router-link>
        </form>


        <form style="display: none" method="POST" :action="acsPostData.action" ref="acsForm">
            <input type="hidden" name="IssuedFrom" :value="store.app.value" />
            <input type="hidden" name="ReturnUrl" :value="store.returnUrl.value" />
            <input type="hidden" name="JWT" :value="acsPostData.jwt" />
        </form>
    </Bubble>
</template>

<i18n lang="yaml" src="./locale/Login.yaml" />

<script lang="ts" setup>
import { ref, computed, onMounted, nextTick, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from '@/store';
import { getBestMatchingLocale } from '@/locale';
import Loader from '@pf/vue3/Loader.vue';
import Bubble from '../components/Bubble.vue';
import axios from 'axios';


const globalI18n = useI18n({ useScope: 'global' });
const i18n = useI18n();
const { t } = i18n;

const router = useRouter();
const route = useRoute();
const store = useStore();

const acsForm = ref<HTMLFormElement | null>(null);
const usernameInput = ref<HTMLInputElement | null>(null);
const passwordInput = ref<HTMLInputElement | null>(null);

const validatingExisting = ref(true);
const password = ref('');
const validating = ref(false);
const attemptedLogin = ref(false);

const errorMessage = ref<string | null>(null);
const translatedMessage = computed(() => translateErrorMessage(errorMessage.value) )
const passwordExpired = ref(false);
const passwordInvalidated = ref(false);

const acsPostData = ref({
    action: '',
    jwt: '' as string | null
});


interface LoginValidateRequest
{
    JWT: string;
    App: number;
    AppSwitch: boolean;
}

interface LoginValidateResponse
{
    Renewed?: string;
    ACSUrl: string;
}


interface LoginRequest
{
    Username: string;
    Password: string;
    App: number;
    Locale?: string;
}


interface LoginResponse
{
    JWT: string;
    ACSUrl: string;
    WachtwoordVerlopen: boolean;
    WachtwoordOngeldig: boolean;
    CheckMFA: boolean;
}

// Special constructie voor tonen vertaling van error zie https://github.com/intlify/vue-i18n-next/issues/1396
function translateErrorMessage(value: string | null | undefined) {
    switch (value) {
        case 'nousernamepassword': return computed(_ => t('error.nousernamepassword')) 
        case 'nousername': return computed(_ => t('error.nousername')) 
        case 'nopassword': return computed(_ => t('error.nopassword')) 
        case 'invalid': return computed(_ => t('error.invalid')) 
        case 'noaccess': return computed(_ => t('error.noaccess')) 
        case 'unknown': return computed(_ => t('error.unknown')) 
    }
}

const username = computed<string>(() =>
{
    return store.username.value;
});


const usernameValid = computed<boolean>(() =>
{
    return username.value.trim() != '';
});

        
const passwordValid = computed<boolean>(() =>
{            
    return password.value.trim() != '';
});



onMounted(() =>
{
    document.title = t('title');
    watch(i18n.locale, () => { document.title = t('title'); });


    if (!!route.query.c)
    {
        store.readInputJwt(route.query.c.toString());

        const jwtDecoded = store.getJwtDecoded();
        if (!!jwtDecoded && !!jwtDecoded.lng)
        {
            // De taal overnemen van de binnenkomende JWT. Deze is nog in LEF stijl (nlNL), omzetten naar ISO (nl-NL)
            const lng = jwtDecoded.lng.length == 4
                        ? jwtDecoded.lng.substring(0, 2) + '-' + jwtDecoded.lng.substring(2)
                        : jwtDecoded.lng;

            globalI18n.locale.value = getBestMatchingLocale(lng);
        }
    }

    
    validateExisting();
});


function setUsername(event: Event)
{
    const value = (event.target as HTMLInputElement).value;
    store.username.value = value;
}


async function validateExisting()
{
    const jwt = store.getJwtString();
    if (jwt !== null)
    {
        const response = await axios.post<LoginValidateResponse>('/v2/login/validate', 
            {
                JWT: jwt,
                App: store.app.value,
                AppSwitch: store.appSwitch.value
            } as LoginValidateRequest);

        if (response.status == 200)
        {
            loginSuccess(response.data.Renewed, response.data.ACSUrl);
            return;
        }

        // Geen foutmelding tonen bij binnenkomst, simpelweg het loginscherm.
        // Wel de ?c= parameter weghalen.
        router.replace({ name: 'login', query: { r: store.returnUrl.value, app: store.app.value } });
    }

    validatingExisting.value = false;

    nextTick(() =>
    {
        if (!!username.value)
            passwordInput.value?.focus();
        else
            usernameInput.value?.focus();
    });
}


async function validateLogin()
{
    attemptedLogin.value = true;

    if (!usernameValid.value && !passwordValid.value)
    {
        showMessage('nousernamepassword');
        usernameInput.value?.focus();
        return;
    }

    if (!usernameValid.value)
    {
        showMessage('nousername');
        usernameInput.value?.focus();
        return;
    }

    if (!passwordValid.value)
    {
        showMessage('nopassword');
        passwordInput.value?.focus();
        return;
    }

    clearMessage();
    let success = false;
    validating.value = true;

    try
    {
        const response = await axios.post<LoginResponse>('/v2/login', {
                Username: username.value,
                Password: password.value,
                App: store.app.value,
                Locale: i18n.locale.value
            } as LoginRequest);
        
        switch (response.status)
        {
            case 200:
                if (response.data.CheckMFA) {
                    store.mfaJWT.value = response.data.JWT
                    router.push({ name: 'mfa' });
                    return;
                }
                if (response.data.WachtwoordVerlopen) {
                    passwordExpired.value = true;
                    return;
                }
                if (response.data.WachtwoordOngeldig) {
                    passwordInvalidated.value = true;
                    return;
                }
                loginSuccess(response.data.JWT, response.data.ACSUrl);
                success = true;
                break;

            case 401:
                showMessage('invalid');

                // Wachtwoord is het meest waarschijnlijke
                passwordInput.value?.focus();
                break;

            case 403:
                showMessage('noaccess');
                break;

            default:
                showMessage('unknown');
                break;
        }
    }
    finally
    {
        if (!success)
            validating.value = false;
    }
}


function loginSuccess(jwt: string | undefined, acsUrl: string)
{
    acsPostData.value = {
        action: acsUrl,
        jwt: jwt || store.getJwtString()
    };
    
    nextTick(() => {
        acsForm.value?.submit();
    });
}


function clearMessage()
{
    errorMessage.value = null;
}


function showMessage(value: string)
{
    errorMessage.value = value;
}
</script>

<style lang="scss">
@import "../variables.scss";

.login-loading
{
    margin-top: 60px;
    text-align: center;
}


.login
{   
    padding-top: 42px;
    padding-left: 65px;
    padding-right: 65px;
    text-align: center;

    .form-control
    {
        margin-bottom: 1rem;
        width: 100%;
    }

    .btn
    {
        margin-left: auto;
        margin-right: auto;
    }


    .status
    {
        color: white;
        margin-top: .5rem;
        margin-bottom: .5rem;
        height: 2em;
    }


    .resetpassword
    {
        display: block;
        color: #379;
    }
}

.password-expired, .password-invalidated {
    padding: 15px;
    text-align: center;
    color: white;
    background-color: #337ab7;
    border: solid 1px #2e6da4;
    border-radius: 5px;
    
    p {
        text-align: center;
        margin: 0 0 5px 0;
    }

    button {
        color: white;
        background-color: transparent;
        text-decoration: underline;
        border: 0;
        margin-top: 10px;

        &:hover {
            cursor: pointer;
        }
    }
}
</style>
