export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "nl": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord opnieuw instellen"])},
        "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als de ingevoerde gegevens bij ons bekend zijn is er een e-mail verzonden om uw wachtwoord te wijzigen."])},
        "loginLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik hier om terug te gaan naar het inlogscherm"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le mot de passe"])},
        "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si les données saisies nous sont connues un email a été envoyé pour changer votre mot de passe"])},
        "loginLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à l'écran de connexion"])}
      },
      "de": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort neu einrichten"])},
        "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn uns die eingegebenen Daten bekannt sind, wurde eine E-Mail für die Änderung Ihres Passworts versendet."])},
        "loginLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie hier, um zum Anmeldebildschirm zurückzukehren"])}
      }
    }
  })
}