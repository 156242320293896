import { createApp } from 'vue'
import { createI18n } from 'vue-i18n';
import App from './App.vue'
import router from './router'
import axios from 'axios';
import { DefaultLocale, getBestMatchingLocale } from '@/locale';


axios.defaults.validateStatus = (_status: number) => 
{
    // Alle response codes terug geven als response, geen exception throwen
    return true;
};


const i18n = createI18n({
    locale: getBestMatchingLocale(),
    fallbackLocale: DefaultLocale,
    legacy: false,
    missingWarn: false,
    fallbackWarn: false
  });  


const app = createApp(App);

app.use(router);
app.use(i18n);


// v-show doet display:none, v-visible toevoegen om visibility:hidden toe te
// kunnen passen zodat de ruimte van het element nog wel gereserveerd blijft
app.directive('visible', {
    beforeMount(el: HTMLElement, binding: any) 
    {
        el.style.visibility = (!!binding.value) ? 'visible' : 'hidden';
    },

    updated(el: HTMLElement, binding: any)
    {
        el.style.visibility = (!!binding.value) ? 'visible' : 'hidden';
    }
});


app.mount('#app');