<template>
    <router-view />
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router';
import { useStore } from '@/store';

const router = useRouter();
const store = useStore();

let routeChecked = false;

router.beforeEach((to) =>
{
    if (routeChecked)
        return;

    routeChecked = true;

    if (!!to.query.app)
    {
        store.app.value = parseInt(to.query.app.toString()) || 1;
        store.appSwitch.value = to.name === 'appSwitch';
    }

    if (!!to.query.r)
        store.returnUrl.value = to.query.r.toString();

});
</script>

<style lang="scss">
@import "@/variables.scss";


html, #app
{
    height: 100%;
}

body 
{
    background-color: $sso-background;
    font-size: $platform-fontsize;
    font-family: $platform-normalfont;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.form-control 
{
    display: block;
    box-sizing: border-box;
    padding: .8em;
    border: 1px solid #ccc;
    border-radius: 4px;

    &.error
    {
        border-color: #dc3545;
        background: #fbe9eb;
    }
}

.btn
{
    background: $platform-btn-primary-background;
    border: $platform-btn-primary-border;
    color: $platform-primary-text;
    box-sizing: border-box;
    padding: .50rem .9rem .50rem .9rem;
    border-radius: $platform-btn-borderradius;
    cursor: pointer;

    &:hover
    {
        background: $platform-btn-primary-hover-background;
        border: $platform-btn-primary-hover-border;
    }


    &:disabled
    {
        background: #808080;
        border: solid 1px #404040;
        color: #c0c0c0;
        cursor: not-allowed;
    }
}
</style>
