<template>
    <Bubble>
        <div v-if="validating" class="resetPassword-loading">
            <Loader :style="'white'" />
        </div>

        <form v-else class="resetPassword" @submit.prevent="resetPassword">
            <input class="form-control" id="username" ref="usernameInput" :class="{ error: attemptedReset && !usernameValid }" :disabled="validating" :placeholder="t('placeholders.username')" type="text" :value="username" @input="setUsername" autofocus autocomplete="username" />

            <input type="submit" id="reset" class="btn" :value="t('submit')" />

            <div class="status">
               <p> {{ translatedMessage?.value }}</p>
            </div>
        </form>
    </Bubble>
</template>

<i18n lang="yaml" src="./locale/ResetPassword.yaml" />

<script lang="ts" setup>
import { ref, computed, onMounted, nextTick, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useStore } from '@/store';
import Loader from '@pf/vue3/Loader.vue';
import Bubble from '../components/Bubble.vue';
import axios from 'axios';

const i18n = useI18n();
const { t } = i18n;

const router = useRouter();
const store = useStore();


const usernameInput = ref<HTMLInputElement | null>(null);

const validating = ref(false);
const attemptedReset = ref(false);
const errorMessage = ref<string | null>(null);
const translatedMessage = computed(() => translateErrorMessage(errorMessage.value))

// Special constructie voor tonen vertaling van error zie https://github.com/intlify/vue-i18n-next/issues/1396
function translateErrorMessage(value: string | null | undefined) {
    switch (value) {
        case 'nousername': return computed(_ => t('error.nousername')) 
        case 'tooManyRequests': return computed(_ => t('error.tooManyRequests')) 
        case 'unknown': return computed(_ => t('error.unknown')) 
    }
}


interface RequestPasswordResetRequest
{
    Email: string;
    Locale?: string;
}


const username = computed<string>(() =>
{
    return store.username.value;
});

const usernameValid = computed<boolean>(() =>
{
    return username.value.trim() != '';
});



onMounted(() =>
{
    document.title = t('title');
    watch(i18n.locale, () => { document.title = t('title'); });

    nextTick(() =>
    {
        usernameInput.value?.focus();
    });
});



function setUsername(event: Event)
{
    const value = (event.target as HTMLInputElement).value;
    store.username.value = value;
};


async function resetPassword()
{
    attemptedReset.value = true;

    if (!usernameValid.value)
    {
        showMessage('nousername');
        return;
    }

    clearMessage();
    validating.value = true;
    try
    {
        const response = await axios.post('/v2/resetpassword/request', 
            {
                Email: username.value,
                Locale: i18n.locale.value
            } as RequestPasswordResetRequest);

        switch (response.status)
        {
            case 200:
                router.push({ name: 'resetPasswordSent' });
                break;

            case 429:
                showMessage('tooManyRequests');
                break;

            default:
                showMessage('unknown');
                break;
        }
    }
    finally
    {
        validating.value = false;
    }
}


function clearMessage()
{
    errorMessage.value = null;
}


function showMessage(value: string)
{
    errorMessage.value = value;
}
</script>

<style lang="scss">
@import "../variables.scss";

.resetPassword
{
    padding-top: 42px;
    padding-left: 65px;
    padding-right: 65px;
    text-align: center;

    .form-control
    {
        margin-bottom: 1rem;
        width: 100%;
    }

    .btn
    {
        margin-left: auto;
        margin-right: auto;
    }


    .status
    {
        color: white;
        margin-top: .5rem;
        margin-bottom: .5rem;
        height: 2em;
    }
}


.resetPassword-loading
{
    margin-top: 60px;
    text-align: center;
}
</style>
