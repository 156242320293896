import { type Ref, ref } from 'vue';
import { uuid } from "vue-uuid";

export interface ToastrContainer {
    allToastrs: Ref<Toastr[]>;
    error(message: string): void;
    success(message: string): void;
    warning(message: string): void;
    information(message: string): void;
    close(id: string): void;
}

export interface Toastr {
    id: string;
    message: string;
    type: string;
}

const container: ToastrContainer = {
    allToastrs: ref([]),
    warning,
    error,
    information,
    success,
    close
}

export function useToastr(): ToastrContainer {
    return container;
}


function createToastr(type: string, message: string, timeout: number = 5000) {
    const id = uuid.v4();
    const toastr = { id: id, message: message, type: type } as Toastr;

    useToastr().allToastrs.value.push(toastr);

    setTimeout(() => {
        close(id);
    }, timeout)
}

function close(id: string) {
    const allItems = useToastr().allToastrs.value as Toastr[];
    useToastr().allToastrs.value = allItems.filter(t => t.id != id);
}

function error(message: string) {
    return createToastr("error", message);
}

function warning(message: string) {
    return createToastr("warning", message);
}

function information(message: string) {
    return createToastr("information", message);
}

function success(message: string) {
    return createToastr("success", message);
}
