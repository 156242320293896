"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { id: "passwordRequirements" };
var _hoisted_2 = { id: "title" };
var _hoisted_3 = { class: "requirementsList" };
var _hoisted_4 = {
    key: 0,
    class: "error"
};
var _hoisted_5 = {
    key: 5,
    class: "error"
};
var _hoisted_6 = {
    key: 6,
    class: "error"
};
var vue_3 = require("vue");
var vue_i18n_1 = require("vue-i18n");
exports.default = (0, vue_1.defineComponent)({
    __name: 'PasswordRequirementsChecker',
    props: {
        wachtwoordRequirements: {
            type: Object,
            required: true
        },
        currentPassword: String,
        newPassword: {
            type: String,
            required: true,
        },
        newPasswordConfirm: {
            type: String,
            required: true,
        },
        checkMatchingCurrentNew: {
            type: Boolean,
            default: false
        },
        checkNewPaswswordConfirmed: {
            type: Boolean,
            default: true
        }
    },
    setup: function (__props, _a) {
        var __expose = _a.expose;
        var props = __props;
        __expose({
            verify: verify,
        });
        var t = (0, vue_i18n_1.useI18n)().t;
        var passedLengthRequirement = (0, vue_3.computed)(function () { return props.newPassword.length >= props.wachtwoordRequirements.minimumLength; });
        var passedCapitalRequirement = (0, vue_3.computed)(function () { return /[A-Z]/.test(props.newPassword); });
        var passedNumberRequirement = (0, vue_3.computed)(function () { return /[0-9]/.test(props.newPassword); });
        var passedSymbolRequirement = (0, vue_3.computed)(function () { return /[-!"#$%&'()*+,./:;<=>?@\[\]^_`{|}~]/.test(props.newPassword); });
        var containsUserData = (0, vue_3.computed)(function () {
            var loweredPassword = props.newPassword.toLowerCase();
            var containsUserdata = props.wachtwoordRequirements.forbiddenUserInfo.some(function (fv) { return loweredPassword.includes(fv); });
            return containsUserdata;
        });
        var currentPasswordMatchingNew = (0, vue_3.computed)(function () {
            return props.checkMatchingCurrentNew && props.currentPassword.length > 0 && props.newPassword.length > 0 && props.currentPassword === props.newPassword;
        });
        var newPasswordConfirmed = (0, vue_3.computed)(function () { return !props.checkNewPaswswordConfirmed || props.newPassword === props.newPasswordConfirm; });
        function verify() {
            var messages = [];
            var verificationResult = { IsValid: true, Message: '' };
            if (props.wachtwoordRequirements.minimumLength && !passedLengthRequirement.value)
                messages.push(t('errors.minLength', { aantal: props.wachtwoordRequirements.minimumLength }));
            if (props.wachtwoordRequirements.includeCapital && !passedCapitalRequirement.value)
                messages.push(t('errors.includeCapital'));
            if (props.wachtwoordRequirements.includeNumber && !passedNumberRequirement.value)
                messages.push(t('errors.includeNumber'));
            if (props.wachtwoordRequirements.includeSymbol && !passedSymbolRequirement.value)
                messages.push(t('errors.includeSymbol'));
            if (containsUserData.value)
                messages.push(t('errors.cantContainUserData'));
            if (currentPasswordMatchingNew.value)
                messages.push(t('errors.passwordsTheSame'));
            if (!newPasswordConfirmed.value)
                messages.push(t('errors.passwordsMustMatch'));
            if (messages.length) {
                verificationResult.IsValid = false;
                verificationResult.Message = '<ul>';
                messages.forEach(function (m) {
                    verificationResult.Message += '<li>' + m + '</li>';
                });
                verificationResult.Message += '</ul>';
            }
            return verificationResult;
        }
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createElementVNode)("span", _hoisted_2, (0, vue_2.toDisplayString)((0, vue_2.unref)(t)('labels.title')), 1),
                (0, vue_2.createElementVNode)("ul", _hoisted_3, [
                    (containsUserData.value)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("li", _hoisted_4, (0, vue_2.toDisplayString)((0, vue_2.unref)(t)('errors.cantContainUserData')), 1))
                        : (0, vue_2.createCommentVNode)("", true),
                    (__props.wachtwoordRequirements.minimumLength)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("li", {
                            key: 1,
                            class: (0, vue_2.normalizeClass)({ passed: passedLengthRequirement.value })
                        }, (0, vue_2.toDisplayString)((0, vue_2.unref)(t)('labels.minLength', { aantal: __props.wachtwoordRequirements.minimumLength })), 3))
                        : (0, vue_2.createCommentVNode)("", true),
                    (__props.wachtwoordRequirements.includeCapital)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("li", {
                            key: 2,
                            class: (0, vue_2.normalizeClass)({ passed: passedCapitalRequirement.value })
                        }, (0, vue_2.toDisplayString)((0, vue_2.unref)(t)('labels.includeCapital')), 3))
                        : (0, vue_2.createCommentVNode)("", true),
                    (__props.wachtwoordRequirements.includeNumber)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("li", {
                            key: 3,
                            class: (0, vue_2.normalizeClass)({ passed: passedNumberRequirement.value })
                        }, (0, vue_2.toDisplayString)((0, vue_2.unref)(t)('labels.includeNumber')), 3))
                        : (0, vue_2.createCommentVNode)("", true),
                    (__props.wachtwoordRequirements.includeSymbol)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("li", {
                            key: 4,
                            class: (0, vue_2.normalizeClass)({ passed: passedSymbolRequirement.value })
                        }, (0, vue_2.toDisplayString)((0, vue_2.unref)(t)('labels.includeSymbol')), 3))
                        : (0, vue_2.createCommentVNode)("", true),
                    (currentPasswordMatchingNew.value)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("li", _hoisted_5, (0, vue_2.toDisplayString)((0, vue_2.unref)(t)('errors.passwordsTheSame')), 1))
                        : (0, vue_2.createCommentVNode)("", true),
                    (!currentPasswordMatchingNew.value && !newPasswordConfirmed.value)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("li", _hoisted_6, (0, vue_2.toDisplayString)((0, vue_2.unref)(t)('errors.passwordsMustMatch')), 1))
                        : (0, vue_2.createCommentVNode)("", true)
                ])
            ]));
        };
    }
});
