<template>
  <div class="bubble-container">
    <div class="bubble-contents">
      <div class="locales">
        <a 
          v-for="locale in visibleLocales"
          :key="locale.iso"
          href="#"
          @click.prevent="switchLocale(locale)">
          <img 
            :src="locale.flagIcon"
            width="20"
            height="20"
            class="flag"
            :title="t('locale.tooltip', 0, { locale: locale.iso })"
            alt="" />
        </a>
      </div>

      <img src="../assets/lef.svg" width="80" height="80" class="appicon" alt="LEF" v-if="store.app.value !== 2" />
      <img src="../assets/max.svg" width="80" height="80" class="appicon" alt="MAX" v-if="store.app.value === 2" />

      <div class="slot">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<i18n lang="yaml">
nl:
  locale:
    tooltip: 'Nederlands'

fr:
  locale:
    tooltip: 'Français'

de:
  locale:
    tooltip: 'Deutsch'
</i18n>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from '@/store';
import { SSOLocale, supportedLocales } from '@/locale';
import Cookies from 'js-cookie';


const globalI18n = useI18n({ useScope: 'global' });
const i18n = useI18n();
const { t } = i18n;

const store = useStore();

const visibleLocales = computed<Array<SSOLocale>>(() =>
{
  const currentLocale = globalI18n.locale.value;
  return supportedLocales.filter(l => l.iso !== currentLocale);
});


function switchLocale(locale: SSOLocale)
{
  Cookies.set('SSOLocale', locale.iso);
  globalI18n.locale.value = locale.iso;
}
</script>

<style lang="scss">
.bubble-container 
{
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    background-image: url("../assets/background.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

.bubble-contents
{
  margin: auto;
  width: 410px;

  .locales
  {
    margin-bottom: 15px;
    text-align: center;

    .flag
    {
      //margin-left: 1rem;
      padding: 5px;
    }
  }

  .appicon
  {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
  }
}
</style>
