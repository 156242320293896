"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var CheckboxStore_1 = require("./CheckboxStore");
exports.default = (0, vue_1.defineComponent)({
    props: {
        modelValue: Boolean,
        label: String,
        readonly: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        id: String,
        size: {
            type: String,
            default: '24px'
        }
    },
    data: function () {
        return {
            // Geef een uniek ID aan de outline, die moet namelijk via een ID maar als er checkboxes van meerdere
            // sizes op de page zijn dan geeft dit conflicten en renderen ze verkeerd ook al zijn alle viewboxen gelijk.
            // SVG rendering is weird.
            checkboxID: (0, CheckboxStore_1.getNextCheckboxID)()
        };
    },
    methods: {
        toggle: function () {
            if (this.readonly || this.disabled)
                return;
            this.$emit("update:modelValue", !this.modelValue);
        },
    },
});
