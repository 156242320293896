import Cookies from "js-cookie";

export interface SSOLocale
{
    iso: string;
    flagIcon: any;
}


export const supportedLocales: SSOLocale[] = [
    {
        iso: 'nl-NL',
        flagIcon: require('../assets/flag_nl.png')
    },

    {
        iso: 'fr-BE',
        flagIcon: require('../assets/flag_fr.png')
    },

    {
        iso: 'de-DE',
        flagIcon: require('../assets/flag_de.png')
    }
];


export const DefaultLocale = 'nl-NL';


function getLocales(preferred: string | undefined): readonly string[]
{
    const result = [];

    if (!!preferred)
        result.push(preferred);

    // Als eerste controleren of de locale meegegeven is als parameter. Dit gebeurt
    // bijvoorbeeld in de link van de wachtwoord reset e-mail, zodat je dezelfde taal houdt
    // ook al open je die link in een andere browser / op een ander device.
    const urlParams = new URLSearchParams(window.location.search);
    const urlLocale = urlParams.get('locale');
    if (!!urlLocale)
        result.push(urlLocale);

    // Dan kijken of dit vorige keer is opgeslagen. Dit kan afwijken van de JWT, welke alleen
    // van de laatste keer inloggen is.
    const localeCookie = Cookies.get('SSOLocale');
    if (!!localeCookie)
        result.push(localeCookie);

    // Als laatste de voorkeur talen van de browser gebruiken
    return result.concat(navigator.languages || []);
}


export function getBestMatchingLocale(preferred?: string): string
{
    const userLocales = getLocales(preferred);
    const supportedLocaleKeys = supportedLocales.map(l => l.iso);

    for (const locale of userLocales)
    {
        // Als het een directe match is, die gebruiken
        if (supportedLocaleKeys.hasOwnProperty(locale))
            return locale;

        const separatorPos = locale.indexOf('-');
        const language = separatorPos >= 0 ? locale.substring(0, separatorPos) : locale;

        if (!language)
            continue;

        // Als er een andere locale is met dezelfde taal maar een ander land, die gebruiken (bijvoorbeeld fr-FR niet aanwezig, dan fr-BE gebruiken als fallback)
        const match = supportedLocaleKeys.find(l => l.startsWith(language + '-'));
        if (!!match)
            return match;
    }

    // Default
    return DefaultLocale;
}