import script from "./Bubble.vue?vue&type=script&lang=ts&setup=true"
export * from "./Bubble.vue?vue&type=script&lang=ts&setup=true"

import "./Bubble.vue?vue&type=style&index=0&id=078249d5&lang=scss"
/* custom blocks */
import block0 from "./Bubble.vue?vue&type=custom&index=0&blockType=i18n&lang=yaml"
if (typeof block0 === 'function') block0(script)


const __exports__ = script;

export default __exports__