export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "nl": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord opnieuw instellen"])},
        "placeholders": {
          "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres..."])}
        },
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset wachtwoord"])},
        "error": {
          "nousername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul uw e-mailadres in"])},
          "tooManyRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is al een wachtwoord reset aangevraagd, controleer uw e-mail spam box of probeer het later opnieuw"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout tijdens resetten, probeer het later opnieuw"])}
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le mot de passe"])},
        "placeholders": {
          "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email..."])}
        },
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le mot de passe"])},
        "error": {
          "nousername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre adresse email"])},
          "tooManyRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une réinitialisation du mot de passe a déjà été demandée, veuillez réessayer plus tard"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite lors de la demande de modification de votre mot de passe"])}
        }
      },
      "de": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort neu einrichten"])},
        "placeholders": {
          "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse ..."])}
        },
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])},
        "error": {
          "nousername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihre E-Mail-Adresse ein"])},
          "tooManyRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Zurücksetzen des Passworts wurde bereits angefordert. Bitte überprüfen Sie den Spam-Ordner Ihres E-Mail-Postfachs oder versuchen Sie es später noch einmal."])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Zurücksetzen, versuchen Sie es bitte später noch einmal"])}
        }
      }
    }
  })
}