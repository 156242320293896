export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "nl": {
        "labels": {
          "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Minstens ", _interpolate(_named("aantal")), " tekens."])},
          "includeCapital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minstens één hoofdletter."])},
          "includeNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minstens één cijfer."])},
          "includeSymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minstens één speciaal teken."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoordeisen:"])}
        },
        "errors": {
          "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wachtwoord moet minstens ", _interpolate(_named("aantal")), " tekens lang zijn."])},
          "includeCapital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord moet minstens één hoofdletter bevatten."])},
          "includeNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord moet minstens één cijfer bevatten."])},
          "includeSymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord moet minstens één speciaal teken bevatten."])},
          "cantContainUserData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord mag geen gebruikersgegevens bevatten."])},
          "passwordsTheSame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe wachtwoord mag niet gelijk zijn aan huidig wachtwoord."])},
          "passwordsMustMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoorden komen niet overeen."])}
        }
      },
      "fr": {
        "labels": {
          "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Avoir au moins ", _interpolate(_named("aantal")), " caractères"])},
          "includeCapital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins une lettre majuscule."])},
          "includeNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins un chiffre."])},
          "includeSymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins un caractère spécial."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["les conditions requises pour un mot de passe:"])}
        },
        "errors": {
          "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le mot de passe doit comporter au moins ", _interpolate(_named("aantal")), " caractères."])},
          "includeCapital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit contenir au moins une lettre majuscule."])},
          "includeNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit contenir au moins un chiffre."])},
          "includeSymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit contenir au moins un caractère spécial."])},
          "cantContainUserData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe ne peut contenir aucune donnée utilisateur."])},
          "passwordsTheSame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nouveau mot de passe ne peut pas être le même que votre mot de passe actuel."])},
          "passwordsMustMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe ne correspondent pas."])}
        }
      },
      "de": {
        "labels": {
          "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mindestens ", _interpolate(_named("aantal")), " Zeichen lang."])},
          "includeCapital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 1 Großbuchstabe"])},
          "includeNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 1 Zahl"])},
          "includeSymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 1 Sonderzeichen"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwortanforderungen:"])}
        },
        "errors": {
          "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Passwort muss mindestens ", _interpolate(_named("aantal")), " Zeichen lang sein."])},
          "includeCapital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort muss mindestens 1 Großbuchstaben enthalten."])},
          "includeNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort muss mindestens 1 Zahl enthalten."])},
          "includeSymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort muss mindestens 1 Sonderzeichen enthalten."])},
          "cantContainUserData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort darf keine Benutzerdaten enthalten."])},
          "passwordsTheSame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das neue Passwort darf nicht mit dem aktuellen Passwort übereinstimmen."])},
          "passwordsMustMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Passwörter sind nicht identisch"])}
        }
      }
    }
  })
}