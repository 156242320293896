import { Ref, ref } from 'vue';
import Cookies from 'js-cookie';
import jwt_decode, { JwtPayload } from 'jwt-decode';


export interface Store
{
    username: Ref<string>;
    app: Ref<number>;
    appSwitch: Ref<boolean>;
    returnUrl: Ref<string | null>;
    mfaJWT: Ref<string | null>
    
    readInputJwt(cookieName: string): void;
    getJwtString(): string | null;
    getJwtDecoded(): any;
}


export interface PlatformJwtPayload extends JwtPayload
{
    // Voor nu alleen toegevoegd wat we in dit frontend nodig hebben
    lng?: string;
}




const store: Store = {
    username: ref(''),
    app: ref(1),
    appSwitch: ref(false),
    returnUrl: ref(null),
    mfaJWT: ref(null),

    readInputJwt,
    getJwtString,
    getJwtDecoded,
}


export function useStore(): Store
{
    return store;
}




let jwtString: string | null = null;
let jwtDecoded: PlatformJwtPayload | null = null;


function readInputJwt(cookieName: string)
{
    const jwtCookie = Cookies.get(cookieName);  
    if (!jwtCookie)
        return;

    // Cookie meteen verwijderen zodat ze niet opstapelen, anders loop je het risico 
    // op fouten van de browser omdat het request te groot wordt
    Cookies.remove(cookieName);

    try
    {
        const decoded = jwt_decode(jwtCookie);
        if (!!decoded)
        {
            jwtString = jwtCookie;
            jwtDecoded = decoded as PlatformJwtPayload;
        }
    }
    catch (InvalidTokenError)
    {
        jwtString = null;
        jwtDecoded = null;
    }
}

function getJwtString(): string | null
{
    return jwtString;
}

function getJwtDecoded(): PlatformJwtPayload | null
{
    return jwtDecoded;
}