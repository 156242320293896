<template>
    <Bubble>
        <div class="changed">
            {{ t('changed') }}

            <router-link :to="{ name: 'login' }" class="continue">{{ t('loginLink') }}</router-link>
        </div>
    </Bubble>
</template>

<i18n lang="yaml" src="./locale/Changed.yaml" />

<script lang="ts" setup>
import { onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import Bubble from '../components/Bubble.vue';

const i18n = useI18n();
const { t } = i18n;

onMounted(() =>
{
    document.title = t('title');
    watch(i18n.locale, () => { document.title = t('title'); });
});
</script>

<style lang="scss">
.changed
{
    padding-top: 42px;
    padding-left: 65px;
    padding-right: 65px;
    text-align: center;

    color: white;
}


.continue
{
    display: block;
    margin-top: 2em;
    color: #379;
}
</style>
