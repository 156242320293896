export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "nl": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord opnieuw instellen"])},
        "placeholders": {
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe wachtwoord..."])},
          "passwordConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe wachtwoord (nogmaals)..."])}
        },
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset wachtwoord"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw wachtwoord is gewijzigd."])},
        "loginLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik hier om in te loggen"])},
        "passwordRequirements": {
          "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoordeisen: "])},
          "length": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["minimaal ", _interpolate(_named("length")), " tekens lang zijn"])},
          "capital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minimaal 1 hoofdletter"])},
          "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minimaal 1 cijfer"])},
          "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minimaal 1 speciaal teken"])}
        },
        "error": {
          "nopassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul een nieuw wachtwoord in"])},
          "confirmpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De wachtwoorden komen niet overeen"])},
          "notstrongenough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het opgegeven wachtwoord is niet sterk genoeg of gelijk aan je huidige wachtwoord"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout tijdens resetten, probeer het later opnieuw"])},
          "invalidToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze link is niet meer geldig."])},
          "resetPasswordLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik hier om uw wachtwoord te resetten"])}
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le mot de passe"])},
        "placeholders": {
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe..."])},
          "passwordConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmez le mot de passe..."])}
        },
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le mot de passe"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe a été changé."])},
        "loginLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller à l'écran de connexion"])},
        "passwordRequirements": {
          "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["les conditions requises pour un mot de passe:"])},
          "length": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Avoir au moins ", _interpolate(_named("length")), " caractères"])},
          "capital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins une lettre majuscule"])},
          "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins un chiffre"])},
          "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins un caractère spécial"])}
        },
        "error": {
          "nopassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer un mot de passe"])},
          "confirmpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les deux mots de passe ne sont pas les mêmes"])},
          "notstrongenough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe que vous avez saisi n'est pas suffisamment sécurisé ou égal à votre mot de passe actuel"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite lors de la demande de modification de votre mot de passe"])},
          "invalidToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce lien a expiré."])},
          "resetPasswordLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le mot de passe"])}
        }
      },
      "de": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort neu einrichten"])},
        "placeholders": {
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort ..."])},
          "passwordConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort (wiederholen) ..."])}
        },
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Passwort wurde geändert."])},
        "loginLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie hier, um sich anzumelden"])},
        "passwordRequirements": {
          "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwortanforderungen: "])},
          "length": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["mindestens ", _interpolate(_named("length")), " Zeichen lang"])},
          "capital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mindestens 1 Großbuchstabe"])},
          "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mindestens 1 Zahl"])},
          "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mindestens 1 Sonderzeichen"])}
        },
        "error": {
          "nopassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie ein neues Passwort ein"])},
          "confirmpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Passwörter sind nicht identisch"])},
          "notstrongenough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das eingegebene Passwort ist nicht stark genug oder entspricht Ihrem aktuellen Passwort."])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Zurücksetzen, versuchen Sie es bitte später noch einmal"])},
          "invalidToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Link ist nicht mehr gültig."])},
          "resetPasswordLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie hier, um Ihr Passwort zurückzusetzen"])}
        }
      }
    }
  })
}