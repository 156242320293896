export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "nl": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen"])},
        "placeholders": {
          "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres..."])},
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord..."])}
        },
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
        "forgotpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord vergeten?"])},
        "loginLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik hier om terug te gaan naar het inlogscherm"])},
        "error": {
          "nousernamepassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul uw e-mailadres en wachtwoord in"])},
          "nousername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul uw e-mailadres in"])},
          "nopassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul uw wachtwoord in"])},
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres of wachtwoord is incorrect"])},
          "noaccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U heeft geen rechten om in te kunnen loggen"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout tijdens inloggen, probeer het later opnieuw"])},
          "passwordExpiredExplaination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je bedrijf heeft een wachtwoordbeleid waarmee wachtwoorden beperkte tijd geldig zijn."])},
          "passwordEmailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een e-mail verstuurd om het wachtwoord aan te passen."])},
          "passwordInvalidatedExplaination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De beheerder heef je wachtwoord als ongeldig verklaard en moet dus worden aangepast."])}
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
        "placeholders": {
          "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d’utilisateur..."])},
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe..."])}
        },
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
        "forgotpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié?"])},
        "loginLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à l'écran de connexion"])},
        "error": {
          "nousernamepassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre nom d'utilisateur et mot de passe"])},
          "nousername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre nom d'utilisateur"])},
          "nopassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre mot de passe"])},
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom d'utilisateur ou le mot de passe n'est pas correct"])},
          "noaccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas le droit de vous connecter"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s’est produite"])},
          "passwordExpiredExplaination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre entreprise a une politique de mot de passe qui permet aux mots de passe d'être valides pendant un temps limité."])},
          "passwordEmailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un e-mail a été envoyé pour changer le mot de passe."])},
          "passwordInvalidatedExplaination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'administrateur a déclaré votre mot de passe invalide et doit être changé."])}
        }
      },
      "de": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einloggen"])},
        "placeholders": {
          "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse ..."])},
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ..."])}
        },
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einloggen"])},
        "forgotpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen?"])},
        "loginLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie hier, um zum Anmeldebildschirm zurückzukehren"])},
        "error": {
          "nousernamepassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihre E-Mail Adresse und Ihr Passwort ein"])},
          "nousername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihre E-Mail Adresse ein"])},
          "nopassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihr Passwort ein"])},
          "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse oder Passwort ist falsch"])},
          "noaccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben keine Rechte zum Einloggen"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Einloggen, versuchen Sie es später noch einmal"])},
          "passwordExpiredExplaination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Ihrem Unternehmen gibt es eine Passwortrichtlinie, nach der Kennwörter nur eine begrenzte Zeit gültig sind."])},
          "passwordEmailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurde eine E-Mail zur Änderung des Passworts versandt."])},
          "passwordInvalidatedExplaination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Administrator hat Ihr Passwort für ungültig erklärt und es muss geändert werden."])}
        }
      }
    }
  })
}