export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "nl": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessie beëindigd"])},
        "changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze sessie is beëindigd omdat u op een ander tabblad bent ingelogd als een andere gebruiker."])},
        "loginLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik hier om door te gaan"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séance terminée"])},
        "changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette session est terminée car vous êtes connecté en tant qu'utilisateur différent sur un autre onglet."])},
        "loginLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clique ici pour continuer"])}
      },
      "de": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzung beendet"])},
        "changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Sitzung wurde beendet, weil Sie in einem anderen Tabellenblatt als ein anderer Benutzer angemeldet sind."])},
        "loginLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Fortfahren hier klicken"])}
      }
    }
  })
}