import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Login from '@/views/Login.vue';
import Changed from '@/views/Changed.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import ResetPasswordSent from '@/views/ResetPasswordSent.vue';
import ResetPasswordValidate from '@/views/ResetPasswordValidate.vue';
import MFACheck from '@/views/MFACheck.vue';
import { useStore } from '@/store';


const store = useStore();

const routes: Array<RouteRecordRaw> = [
  {
    name: 'login',
    path: '/',
    component: Login
  },
  {
    name: 'changed',
    path: '/changed',
    component: Changed
  },
  {
    name: 'appSwitch',
    path: '/switch',
    component: Login
  },
  {
    name: 'resetpassword',
    path: '/resetpassword',
    component: ResetPassword
  },
  {
    name: 'resetPasswordSent',
    path: '/resetpassword/sent',
    component: ResetPasswordSent
  },
  {
    path: '/resetpassword/validate',
    component: ResetPasswordValidate
  },
  {
    name: 'mfa',
    path: '/mfa',
    component: MFACheck
  }
];


const router = createRouter({
  history: createWebHistory(),
  routes
});


export default router;