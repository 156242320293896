"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var vue_3 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'Loader',
    props: {
        style: {},
        sectionColor: {},
        activeSectionColor: {}
    },
    setup: function (__props) {
        (0, vue_1.useCssVars)(function (_ctx) { return ({
            "d47c95fe": (sectionColor.value),
            "2692c0e7": (activeSectionColor.value)
        }); });
        var props = __props;
        // Voor backwards compatibility met de ingebouwde 'white' style, vandaar dat we 
        // geen default values voor de props gebruiken
        var defaultActiveSectionColor = (0, vue_3.computed)(function () { return props.style === 'white' ? 'white' : '$activeSectionColor'; });
        var sectionColor = (0, vue_3.computed)(function () { return props.sectionColor || '#9a9a9a'; });
        var activeSectionColor = (0, vue_3.computed)(function () { return props.activeSectionColor || defaultActiveSectionColor.value; });
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("svg", {
                width: "31",
                height: "31",
                viewBox: "0 0 31 31",
                class: (0, vue_2.normalizeClass)(["loader", _ctx.style])
            }, _cache[0] || (_cache[0] = [
                (0, vue_2.createStaticVNode)("<path class=\"section section1\" d=\"M15.516,5.107c2.513,0,4.807,0.988,6.553,2.617l3.513-3.512c-2.645-2.533-6.17-4.104-10.065-4.104  c-0.005,0-0.01,0.001-0.016,0.001v5C15.505,5.108,15.51,5.107,15.516,5.107z\"></path><path class=\"section section2\" d=\"M25.328,14.5h4.999c-0.227-3.683-1.717-7.033-4.045-9.575l-3.518,3.518  C24.185,10.058,25.124,12.152,25.328,14.5z\"></path><path class=\"section section3\" d=\"M25.374,15.5c-0.007,2.479-0.824,4.726-2.179,6.488l3.528,3.528c2.265-2.7,3.644-6.205,3.65-10.017H25.374z\"></path><path class=\"section section4\" d=\"M15.516,25.84c-0.005,0-0.01-0.001-0.016-0.001v5c0.005,0,0.01,0.001,0.016,0.001  c4.133,0,7.86-1.757,10.549-4.568l-3.518-3.518C20.754,24.666,18.263,25.84,15.516,25.84z\"></path><path class=\"section section5\" d=\"M8.47,22.737l-3.517,3.518c2.466,2.587,5.815,4.269,9.547,4.531v-5.001  C12.149,25.534,10.04,24.419,8.47,22.737z\"></path><path class=\"section section6\" d=\"M5.658,15.5h-5c0.006,3.804,1.379,7.303,3.635,10l3.53-3.529C6.477,20.21,5.664,17.971,5.658,15.5z\"></path><path class=\"section section7\" d=\"M8.252,8.459L4.734,4.941c-2.32,2.54-3.804,5.884-4.03,9.559h4.999C5.906,12.16,6.84,10.072,8.252,8.459z\"></path><path class=\"section section8\" d=\"M14.5,5.162V0.161c-3.499,0.247-6.652,1.748-9.065,4.066L8.947,7.74C10.457,6.324,12.378,5.388,14.5,5.162z\"></path>", 8)
            ]), 2));
        };
    }
});
